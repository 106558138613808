<div class="container mt-5">
  <button routerLink="/" class="btn btn-transparent ms-n3 mb-3"><i class="fas fa-arrow-left me-2"></i>My Teams</button>
  <div *ngIf="runnerService.currentTeam$ | async as team">
    <h1>{{team.name}}</h1>
    <p>Edit this team's teams info & manage members.</p>

    <div *ngIf="runnerService.checkRunner(team).length > 0" class="alert alert-danger mb-2" role="alert">
      <i class="fa-solid fa-triangle-exclamation me-2"></i><b>Issues with this team:</b>
      <p class="ms-4">(Please complete these fields by
        <b>{{game.currentGame.submissionDeadline.toMillis() | date: 'EEEE, dd MMMM y'}}</b>).</p>
      <ul class="ms-3 mt-1 mb-1">
        <li *ngFor="let error of runnerService.checkRunner(team)">{{error}}</li>
      </ul>
    </div>

    <div class="row">
      <div class="col-lg-8">
        <div class="card bg-light border-0 mt-3 p-2">
          <div class="card-body">
            <h4>Team Info</h4>

            <div class="row">
              <div class="col-lg-6 mb-2">
                <div class="form-group">
                  <label for="teamName">Team Name</label>
                  <input [(ngModel)]="team.name" (ngModelChange)="teamEdited = true;" (keyup.enter)="saveTeam(team)"
                         id="teamName" type="text"
                         autocomplete="off" class="form-control mt-1">
                </div>
              </div>
              <div class="col-lg-6 mb-2">
                <div class="form-group">
                  <label for="group">Group</label>
                  <input [(ngModel)]="team.group" (ngModelChange)="teamEdited = true;" (keyup.enter)="saveTeam(team)"
                         id="group" type="text"
                         autocomplete="off" class="form-control mt-1">
                </div>
              </div>
            </div>

            <label for="group" class="mt-2">Category</label>
            <div class="row">
<!--              <div class="col-xl-4 col-md-6 mt-2">-->
<!--                <div class="card card-team-type shadow mb-3" [class.active]="team.category == 'Solo'"-->
<!--                     (click)="team.category = 'Solo'; runnerService.update(team, 0, true); teamEdited = false;">-->
<!--                  <div class="card-body">-->
<!--                    <h5>Solo</h5>-->
<!--                    <p class="mb-0 small">Scout teams aged 10.5 to 14 without a leader. Teams of 4 to 7 scouts.</p>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="col-xl-4 col-md-6 mt-2">-->
<!--                <div class="card card-team-type shadow mb-3" [class.active]="team.category == 'Led'"-->
<!--                     (click)="team.category = 'Led'; runnerService.update(team, 0, true); teamEdited = false;">-->
<!--                  <div class="card-body">-->
<!--                    <h5>Led</h5>-->
<!--                    <p class="mb-0 small">Scout teams aged 10.5 to 14 with a leader. 4 to 7 members (inc. leader).</p>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
              <div class="col-xl-4 col-md-6 mt-2">
                <div class="card card-team-type shadow mb-3" [class.active]="team.category == 'Explorer'"
                     (click)="team.category = 'Explorer'; runnerService.update(team, 0, true); teamEdited = false;">
                  <div class="card-body">
                    <h5>Explorer</h5>
                    <p class="mb-0 small">Explorer teams aged 14 to 18 without a leader. Teams of 4 to 7 explorers.</p>
                  </div>
                </div>
              </div>
            </div>
            <button (click)="confirmDeleteTeam(deleteTeam, team)" class="btn btn-danger text-white mt-3"><i
              class="fa-regular fa-trash-can me-2"></i>Delete Team
            </button>
            <button *ngIf="teamEdited" (click)="saveTeam(team)" class="btn btn-secondary text-white mt-3 ms-3"><i
              class="fa-solid fa-check me-2"></i>Save Changes
            </button>
          </div>
        </div>

        <h3 class="mt-4">Team Members</h3>

        <div *ngFor="let member of team.members; let i = index" @enterAnimation>
          <div class="card p-2 bg-light border-0 mb-4">
            <div class="card-body">

              <h4>Member {{i + 1}} <span *ngIf="i==0">(Team Leader)</span></h4>
              <div class="row">
                <div class="col-md-6 mb-3">
                  <div class="form-group">
                    <label for="name-{{i}}">Name</label>
                    <input [(ngModel)]="member.name" (ngModelChange)="teamEdited = true;" (keyup.enter)="saveTeam(team)"
                           placeholder="E.g. John Smith" type="text"
                           class="form-control mt-1" id="name-{{i}}" name="name-{{i}}">
                  </div>
                </div>
                <div class="col-md-6 mb-3">
                  <div class="form-group">
                    <label for="dob-{{i}}">Date of Birth</label>
                    <div class="input-group mt-1">
                      <input
                        class="form-control"
                        placeholder="dd/mm/yyyy"
                        name="dp"
                        [(ngModel)]="member.dob"
                        ngbDatepicker
                        #d="ngbDatepicker"
                        (ngModelChange)="teamEdited = true;" (keyup.enter)="saveTeam(team)"
                        [minDate]="NgbDate.from({year: 1930, month: 1, day: 1})"
                        [maxDate]="NgbDate.from({year: 2026, month: 1, day: 1})"
                      />
                      <button class="btn btn-secondary text-white btn-outline-dark fa-solid fa-calendar"
                              (click)="d.toggle()"
                              type="button"></button>
                    </div>
                  </div>
                </div>

                <div class="col-md-5 mb-3">
                  <div class="form-group">
                    <label for="teamName">Phone Number</label>
                    <input [(ngModel)]="member.phone" (ngModelChange)="teamEdited = true;"
                           (keyup.enter)="saveTeam(team)"
                           placeholder="E.g. 01234 567 890"
                           type="tel" class="form-control mt-1" id="phone={{i}}" name="phone={{i}}">
                  </div>
                </div>

                <div class="col-md-3 mb-3">
                  <div class="form-group">
                    <label for="food-{{i}}">Wants Food?</label>
                    <div class="form-check mt-2">
                      <input class="form-check-input" type="checkbox" [(ngModel)]="member.wantsFood"
                             (ngModelChange)="teamEdited = true;" id="food-{{i}}">
                      <label class="form-check-label" for="food-{{i}}">Pie & Peas</label>
                    </div>
                  </div>
                </div>

                <div class="col-md-4 mb-3">
                  <div class="form-group">
                    <label for="diet-{{i}}">Dietary Reqs.</label>
                    <input [(ngModel)]="member.dietaryReqs" (ngModelChange)="teamEdited = true;"
                           (keyup.enter)="saveTeam(team)"
                           placeholder="E.g. Vegetarian, allergies, etc." type="text"
                           class="form-control mt-1" id="diet-{{i}}" name="diet-{{i}}">
                  </div>
                </div>
              </div>

              <div class="d-flex justify-content-end flex-column flex-md-row">
                <button *ngIf="i != 0"
                        (click)="makeTeamLeader(team, i); runnerService.update(team, 0, true); teamEdited = false;"
                        class="btn btn-primary text-white ms-md-3 flex-grow-1 flex-md-grow-0 mb-2 mb-md-0"><i
                  class="fa-solid fa-person-burst me-2"></i>Make Team
                  Leader
                </button>
                <div *ngIf="(runnerService.teams$ | async)?.length > 1" ngbDropdown
                     class="flex-grow-1 flex-md-grow-0 d-flex flex-column flex-md-row">
                  <button type="button"
                          class="btn btn-primary text-white ms-md-3 flex-grow-1 flex-md-grow-0 mb-2 mb-md-0"
                          id="changeTeamDD" ngbDropdownToggle><i
                    class="fa-solid fa-people-arrows me-2"></i>Move to Team...
                  </button>
                  <div ngbDropdownMenu aria-labelledby="changeTeamDD">
                    <button [disabled]="selectedTeam.members?.length >= 7"
                            *ngFor="let selectedTeam of filter(team.id, runnerService.teams$ | async)"
                            (click)="moveToTeam(team, i, selectedTeam)" ngbDropdownItem>{{selectedTeam.name}} (<span
                      [textContent]="selectedTeam.members?.length >= 7 ? 'already full' : selectedTeam.category"></span>)
                    </button>
                  </div>
                </div>
                <button (click)="confirmDeleteMember(deleteMember, team, i)"
                        class="btn btn-danger text-white ms-md-3 flex-grow-1 flex-md-grow-0 mb-2 mb-md-0"><i
                  class="fa-regular fa-trash-can me-2"></i>Delete Member
                </button>
                <button *ngIf="teamEdited" (click)="saveTeam(team)"
                        class="btn btn-secondary text-white ms-md-3 flex-grow-1 flex-md-grow-0 mb-2 mb-md-0"><i
                  class="fa-solid fa-check me-2"></i>Save Changes
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class=" mt-3 mb-5">
          <p *ngIf="team.members?.length >= 7" class="mb-1 text-danger"><i
            class="fa-solid fa-triangle-exclamation mx-2"></i>Teams are of a maximum of 7 members</p>
          <button [disabled]="team.members?.length >= 7"
                  (click)="addMember(team);  runnerService.update(team, 0, true); teamEdited = false;"
                  class="btn btn-outline-primary"><i class="fa-solid fa-plus me-2"></i>Add <span
            [textContent]="team.members?.length > 0 ? 'Another' :'First'"></span> Team Member
          </button>
        </div>

      </div>

      <div class="col-lg-4">
        <div class="card bg-light border-0 mt-3 p-2">
          <div class="card-body">
            <h4>Home Contact</h4>
            <p class="small">Emergency contact, not the team leader</p>

            <div class="form-group mt-3">
              <label for="contactName">Contact Name</label>
              <input [(ngModel)]="team.contactName" (ngModelChange)="teamEdited = true;" (keyup.enter)="saveTeam(team)"
                     type="text"
                     id="contactName" class="form-control mt-1">
            </div>

            <div class="form-group mt-3">
              <label for="contactEmail">Contact Email</label>
              <input [(ngModel)]="team.contactEmail" (ngModelChange)="teamEdited = true;" (keyup.enter)="saveTeam(team)"
                     type="email"
                     id="contactEmail" class="form-control mt-1">
            </div>

            <div class="form-group mt-3">
              <label for="contactPhone">Contact Phone</label>
              <input [(ngModel)]="team.contactPhone" (ngModelChange)="teamEdited = true;" (keyup.enter)="saveTeam(team)"
                     type="tel"
                     id="contactPhone" class="form-control mt-1">
            </div>

            <div class="form-group mt-3">
              <label for="contactAddress">Contact Address</label>
              <input [(ngModel)]="team.contactAddress" (ngModelChange)="teamEdited = true;"
                     (keyup.enter)="saveTeam(team)" type="text"
                     id="contactAddress" class="form-control mt-1">
            </div>

            <button *ngIf="teamEdited" (click)="saveTeam(team)" class="btn btn-secondary text-white mt-3"><i
              class="fa-solid fa-check me-2"></i>Save Changes
            </button>
          </div>
        </div>

        <div class="card bg-light border-0 mt-3 p-2">
          <div class="card-body">
            <h4>Cost</h4>
            <p>Twilight was established in memory of one of our own scouts who sadly passed away in 2010.</p>
            <p>We have since been running this event every year raising more & more for charities such as Alder Hey,
              Astley Hall School and Stroke as the event grows.</p>
            <p>This team has <b>{{team.members.length}} members</b> and cost this year is
              <b>{{game.currentGame.costPerPerson | currency:'GBP'}} per person</b>.</p>
            <div class="row mt-2">
              <div class="col-8">Cost for this team:</div>
              <div
                class="col-4 h5 text-end mb-0">{{game.currentGame.costPerPerson * team.members.length | currency:'GBP'}}</div>
            </div>
            <p class="mt-3 mb-0 small text-primary"><i class="fa-solid fa-circle-info me-2"></i>This doesn't include
              cost for other teams. <a routerLink="/">Go back here</a> to see full costs.</p>
          </div>
        </div>
      </div>

    </div>

    <ng-template #deleteMember let-modal>
      <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Are you sure?</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
      </div>
      <div class="modal-body">
        <p>This member will be permanently deleted! Are you sure you want to delete this member?</p>
        <p><b>Note:</b> If you're deleting this person to add them to another team, you can just move them by selecting
          '<i class="fa-solid fa-people-arrows me-2"></i>Move to Team...'</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('Cancel')">Cancel</button>
        <button type="button" class="btn btn-danger text-white" (click)="modal.close('Delete')"><i
          class="fa-solid fa-trash-can me-2"></i>Delete
        </button>
      </div>
    </ng-template>

    <ng-template #deleteTeam let-modal>
      <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title2">Are you sure?</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
      </div>
      <div class="modal-body">
        <p>This team will be permanently deleted! Are you sure you want to delete this team and it's members?</p>
        <p><b>Note:</b> If there's members left in this team, you can move them to your other teams by clicking '<i
          class="fa-solid fa-people-arrows me-2"></i>Move to Team...' before deleting this team.</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('Cancel')">Cancel</button>
        <button type="button" class="btn btn-danger text-white" (click)="modal.close('Delete')"><i
          class="fa-solid fa-trash-can me-2"></i>Delete
        </button>
      </div>
    </ng-template>

  </div>
  <!--  TODO: Loader -->
</div>
