<div class="container mt-5">
  <h1>Add a running team</h1>

  <div *ngIf="authService.submissionsOpen(); else submissionsClosed">
    <p class="mb-4">Register your running team here. Don't worry, you'll still be able to edit all this info later on so
      feel free to leave the bits you don't know yet empty.</p>
    <p>
      <ngb-progressbar type="primary" [value]="progress()" height="8px"></ngb-progressbar>
    </p>
    <h6 class="mb-5 text-primary">Step {{step}}</h6>


    <!--  Step 1-->
    <div *ngIf="step == 1">
      <h3>Basic Info</h3>
      <p>Let's get some info about your running team.</p>
      <p class="mb-4 text-primary"><i class="fa-solid fa-circle-info me-2"></i>You don't have to fill in everything yet, just the basics with <span class="text-danger fw-900">*</span> for now.</p>
      <div class="form-group mb-4">
        <label for="teamName">Team Name <span class="text-danger">*</span></label>
        <input [(ngModel)]="runner.name" placeholder="E.g. The Winners" type="text"
               class="form-control form-control-lg mt-1" id="teamName" name="teamName" autocomplete="off">
        <small>This can be changed later!</small>
      </div>
      <div class="form-group mb-4">
        <label for="group">Scout Group <span class="text-danger">*</span></label>
        <input [(ngModel)]="runner.group" placeholder="E.g. Eccleston" type="text"
               class="form-control form-control-lg mt-1" name="group" id="group" (keyup.enter)="validate() ? nextStep() : null">
        <small>This can be changed later!</small>
      </div>
    </div>

    <!--  Step 2-->
    <div *ngIf="step == 2">
      <h3>Category <span class="text-danger">*</span></h3>
      <p class="mb-4">Don't worry - you can change whether your team has a leader later on.</p>
      <div class="row mb-4">
<!--        <div class="col-md-3">-->
<!--          <div class="card card-team-type shadow mb-3" [class.active]="runner.category == 'Solo'"-->
<!--               (click)="runner.category = 'Solo'">-->
<!--            <div class="card-body">-->
<!--              <h5>Solo</h5>-->
<!--              <p class="mb-0">Scout teams aged 10.5 to 14 without a leader. Teams of 4 to 7 scouts.</p>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="col-md-3">-->
<!--          <div class="card card-team-type shadow mb-3" [class.active]="runner.category == 'Led'"-->
<!--               (click)="runner.category = 'Led'">-->
<!--            <div class="card-body">-->
<!--              <h5>Led</h5>-->
<!--              <p class="mb-0">Scout teams aged 10.5 to 14 with a leader. 4 to 7 members (inc. leader).</p>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
        <div class="col-md-3">
          <div class="card card-team-type shadow mb-3" [class.active]="runner.category == 'Explorer'"
               (click)="runner.category = 'Explorer'">
            <div class="card-body">
              <h5>Explorer</h5>
              <p class="mb-0">Explorer teams aged 14 to 18 without a leader.</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--  Step 3-->
    <div *ngIf="step == 3">
      <h3>Point of Contact</h3>
      <p class="mb-3">The home contact for the team - not the leader (you'll do that on the next step). If you don't know this yet, you can fill it in later.</p>
      <div class="row mb-4">
        <div class="col-md-6">
          <div class="form-group mb-3">
            <label for="teamName">Contact Name</label>
            <input [(ngModel)]="runner.contactName" placeholder="E.g. John Smith" type="text"
                   class="form-control form-control-lg mt-1" id="contactName" name="contactName">
          </div>
        </div>

        <div class="col-md-6">
          <div class="form-group mb-3">
            <label for="contactEmail">Contact Email</label>
            <input [(ngModel)]="runner.contactEmail" placeholder="E.g. johnsmith@ecclestonscouts.uk" type="text"
                   class="form-control form-control-lg mt-1" name="contactEmail" id="contactEmail">
          </div>
        </div>

        <div class="col-md-6">
          <div class="form-group mb-3">
            <label for="address">Contact Address</label>
            <input [(ngModel)]="runner.contactAddress" placeholder="E.g. 7 Snape Rake Lane, Goosnargh, PR3 2EU"
                   type="text" class="form-control form-control-lg mt-1" id="address" name="address">
          </div>
        </div>

        <div class="col-md-6">
          <div class="form-group mb-3">
            <label for="contactPhone">Contact Phone</label>
            <input [(ngModel)]="runner.contactPhone" placeholder="E.g. 07123 456 789" type="tel"
                   class="form-control form-control-lg mt-1" name="contactPhone" id="contactPhone">
          </div>
        </div>
      </div>
    </div>

    <!--  Step 1-->
    <div *ngIf="step == 4">
      <h3>Team Members</h3>
      <p class="mb-4">Now let us know who's on your team. Make sure to let us know of any allergies / dietary requirements and whether your members want <span class="text-primary">pie & peas</span> at the end. You can skip this and fill it in later if you're not sure who's on your team yet.</p>

      <div *ngIf="runner.members.length == 0">
        <p>Add your first team member by clicking the button below.</p>
      </div>

      <div *ngFor="let member of runner.members; let i = index">
        <div class="card p-2 bg-light border-0 mb-4">
          <div class="card-body">

            <h4>Member {{i+1}} <span *ngIf="i==0">(Team Leader)</span></h4>
            <div class="row">
              <div class="col-md-6 mb-3">
                <div class="form-group">
                  <label for="name-{{i}}">Name</label>
                  <input [(ngModel)]="member.name" placeholder="E.g. John Smith" type="text"
                         class="form-control mt-1" id="name-{{i}}" name="name-{{i}}">
                </div>
              </div>

              <div class="col-md-6 mb-3">
                <div class="form-group">
                      <label for="dob-{{i}}">Date of Birth</label>
                      <div class="input-group mt-1">
                        <input
                          class="form-control"
                          placeholder="dd/mm/yyyy"
                          name="dp"
                          [(ngModel)]="member.dob"
                          ngbDatepicker
                          #d="ngbDatepicker"
                          [minDate]="NgbDate.from({year: 1930, month: 1, day: 1})"
                          [maxDate]="NgbDate.from({year: 2026, month: 1, day: 1})"
                        />
                        <button class="btn btn-secondary text-white btn-outline-dark fa-solid fa-calendar"
                                (click)="d.toggle()"
                                type="button"></button>
                      </div>
                    </div>
              </div>

              <div class="col-md-5 mb-3">
                <div class="form-group">
                  <label for="teamName">Phone Number</label>
                  <input [(ngModel)]="member.phone" placeholder="E.g. 01234 567 890"
                         type="tel" class="form-control mt-1" id="phone={{i}}" name="phone={{i}}">
                </div>
              </div>

              <div class="col-md-3 mb-3">
                <div class="form-group">
                  <label for="food-{{i}}">Wants Food?</label>
                  <div class="form-check mt-2">
                    <input class="form-check-input" type="checkbox" [(ngModel)]="member.wantsFood" id="food-{{i}}">
                    <label class="form-check-label" for="food-{{i}}">Pie & Peas</label>
                  </div>
                </div>
              </div>

              <div class="col-md-4 mb-3">
                <div class="form-group">
                  <label for="diet-{{i}}">Dietary Reqs.</label>
                  <input [(ngModel)]="member.dietaryReqs" placeholder="E.g. Vegetarian, allergies, etc." type="text"
                         class="form-control mt-1" id="diet-{{i}}" name="diet-{{i}}">
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

      <div class=" mt-3 mb-5">
        <p *ngIf="runner.members.length >= 7" class="mb-1 text-danger"><i class="fa-solid fa-triangle-exclamation mx-2"></i>Teams are of a maximum of 7 members</p>
        <button [disabled]="runner.members.length >= 7" (click)="addMember()" class="btn btn-lg btn-outline-primary"><i class="fa-solid fa-plus me-2"></i>Add <span [textContent]="runner.members.length > 0 ? 'Another' :'First'"></span> Team Member</button>
      </div>
    </div>

    <div class="d-flex justify-content-between">
      <button [disabled]="step == 1" class="btn btn-primary btn-lg my-3" (click)="previousStep()"><i class="fa-solid fa-chevron-left me-2"></i>Previous</button>
      <button [hidden]="step == steps" [disabled]="!validate()" class="btn btn-primary btn-lg my-3" (click)="nextStep()">
        {{ (step == 3 && !(!!runner.contactName  || !!runner.contactPhone || !!runner.contactEmail || !!runner.contactPhone)) ? 'Skip' : 'Next'}}
        <i class="fa-solid fa-chevron-right ms-2"></i>
      </button>
      <button [disabled]="!validate()" [hidden]="step != steps" class="btn btn-success text-white btn-lg my-3 ms-1" (click)="add()">
        <i class="fa-solid fa-check me-2"></i>
        {{!(runner.members?.length > 0) ? 'Skip Members and ' : ''}}
        Save Team
      </button>
    </div>
  </div>


  <ng-template #submissionsClosed>
      <div class="alert alert-danger mb-2 mt-4" role="alert">
        <i class="fa-solid fa-triangle-exclamation me-2"></i><b>Warning: </b>
          Running team submissions have now closed and you cannot create teams anymore. If this is an issue, please contact the organising team.
      </div>
    </ng-template>

</div>
